import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/1ev5bFFJO3k">
    <SEO title="3 Reasons the Church Must Change - Why Church?" />
  </Layout>
)

export default SermonPost
